import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import GraphService from '../utils/GraphService';
import { getAccessToken } from '../utils/auth'; // Make sure your path is correct
import './FileUpload.css';

// PrimeReact CSS imports (if needed)
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const graphService = new GraphService();

const FileUpload = () => {
  // --------------------------
  // 1) TEAMS STATE
  // --------------------------
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [teamSearch, setTeamSearch] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);

  // --------------------------
  // 2) CHANNELS STATE
  // --------------------------
  const [channels, setChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [channelSearch, setChannelSearch] = useState('');
  const [selectedChannel, setSelectedChannel] = useState(null);

  // --------------------------
  // 3) FOLDERS STATE
  // --------------------------
  const [folderItems, setFolderItems] = useState([]); 
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [folderSearch, setFolderSearch] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [driveId, setDriveId] = useState(null); // We’ll need this for uploads
  const [folderId, setFolderId] = useState(null); // ID of the selected folder to upload into

  // --------------------------
  // 4) FILE UPLOAD STATE
  // --------------------------
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStepVisible, setUploadStepVisible] = useState(false); // Show/hide the upload UI
  const [loading, setLoading] = useState(false);

  // Refs for focusing inputs if needed
  const teamSearchRef = useRef(null);

  // --------------------------------
  // On mount, fetch user’s Teams
  // --------------------------------
  useEffect(() => {
    fetchTeams();
  }, []);

  // Focus the teamSearch input on mount, if desired
  useEffect(() => {
    if (teamSearchRef.current) {
      teamSearchRef.current.focus();
    }
  }, []);

  // --------------------------------
  // Fetch all Teams for the user
  // --------------------------------
  const fetchTeams = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(['Team.ReadBasic.All']);
      const response = await fetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch Teams: ${response.statusText}`);
      }
      const data = await response.json();
      setTeams(data.value || []);
    } catch (error) {
      console.error('Error fetching Teams:', error);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------
  // Filter & sort Teams when teams/teamSearch changes
  // --------------------------------
  useEffect(() => {
    applyTeamsFilter();
  }, [teams, teamSearch]);

  const applyTeamsFilter = () => {
    const filtered = teams.filter((t) =>
      t.displayName.toLowerCase().includes(teamSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
    );
    setFilteredTeams(filtered);
  };

  // --------------------------------
  // On team select => fetch channels
  // --------------------------------
  const onTeamSelect = (e) => {
    const t = e.value;
    setSelectedTeam(t);
    fetchChannelsForTeam(t.id);
  };

  const fetchChannelsForTeam = async (teamId) => {
    if (!teamId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Channel.ReadBasic.All']);
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch channels: ${response.statusText}`);
      }
      const data = await response.json();
      setChannels(data.value || []);
    } catch (error) {
      console.error('Error fetching channels:', error);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------
  // Filter & sort Channels
  // --------------------------------
  useEffect(() => {
    applyChannelsFilter();
  }, [channels, channelSearch]);

  const applyChannelsFilter = () => {
    const filtered = channels.filter((c) =>
      c.displayName.toLowerCase().includes(channelSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
    );
    setFilteredChannels(filtered);
  };

  // --------------------------------
  // On channel select => fetch root folder for that channel
  // --------------------------------
  const onChannelSelect = (e) => {
    const c = e.value;
    setSelectedChannel(c);
    fetchChannelRootFolder(selectedTeam.id, c.id);
  };

  /**
   *  For each channel, there's a "filesFolder" entry 
   *  => https://graph.microsoft.com/v1.0/teams/{teamId}/channels/{channelId}/filesFolder
   *  This returns a driveItem that points to the folder in SharePoint. 
   */
  const fetchChannelRootFolder = async (teamId, channelId) => {
    if (!teamId || !channelId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Files.ReadWrite.All']);
      // 1) Get the driveItem for that channel
      const resp = await fetch(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/filesFolder`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!resp.ok) {
        throw new Error(`Failed to fetch channel's root folder: ${resp.statusText}`);
      }
      const folderData = await resp.json();
      // folderData => { id, name, parentReference: { driveId }, ... }
      const { id, parentReference } = folderData;
      setDriveId(parentReference?.driveId || null);
      // Next, fetch the children of that root folder
      fetchFolderChildren(parentReference?.driveId, id);
    } catch (error) {
      console.error('Error fetching channel root folder:', error);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------
  // Fetch children of a folder (subfolders + files)
  // --------------------------------
  const fetchFolderChildren = async (theDriveId, theFolderId) => {
    if (!theDriveId || !theFolderId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Files.ReadWrite.All']);
      const url = `https://graph.microsoft.com/v1.0/drives/${theDriveId}/items/${theFolderId}/children`;
      const resp = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (!resp.ok) {
        throw new Error(`Failed to fetch folder items: ${resp.statusText}`);
      }
      const data = await resp.json();
      setFolderItems(data.value || []);
    } catch (error) {
      console.error('Error fetching folder children:', error);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------
  // Filter folders
  // --------------------------------
  useEffect(() => {
    applyFolderFilter();
  }, [folderItems, folderSearch]);

  const applyFolderFilter = () => {
    const filtered = folderItems.filter((f) =>
      f.name.toLowerCase().includes(folderSearch.toLowerCase())
    );
    // Sort subfolders first, then files, or do a simple name sort
    filtered.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true })
    );
    setFilteredFolders(filtered);
  };

  // --------------------------------
  // On folder row select
  // => If it's a "folder" => drill down
  // => If it's a "file", maybe do nothing
  // --------------------------------
  const onFolderSelect = (e) => {
    const item = e.value;
    if (item.folder) {
      // It's a folder => fetch children
      fetchFolderChildren(item.parentReference?.driveId, item.id);
    } else {
      // It's a file => we won't navigate inside it
      console.log('Selected a file, doing nothing...');
    }
    // But let's store the *last selected folder*
    setSelectedFolder(item);
    setFolderId(item.id); // This might be the folder ID we eventually upload to
  };

  // --------------------------------
  // Once we have a selected folder, user can proceed to "Upload Step"
  // --------------------------------
  const goToUploadStep = () => {
    if (!selectedFolder || !selectedFolder.folder) {
      alert('Please select a folder first.');
      return;
    }
    setUploadStepVisible(true);
  };

  // --------------------------------
  // Handle local file selection
  // --------------------------------
  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    const filesWithMetadata = files.map((file) => ({
      file,
      name: file.name,
      progress: 0,
      status: 'pending'
    }));
    setSelectedFiles((prev) => [...prev, ...filesWithMetadata]);
    // Reset input
    event.target.value = null;
  };

  // --------------------------------
  // Basic sanitization for file names
  // --------------------------------
  const sanitizeFileName = (name) => name.replace(/[<>:"/\\|?*]/g, '').trim();

  // --------------------------------
  // Rename local file
  // --------------------------------
  const handleNameChange = (index, newName) => {
    const sanitized = sanitizeFileName(newName) || `Photo_${Date.now()}`;
    const updated = [...selectedFiles];
    updated[index].name = sanitized;
    setSelectedFiles(updated);
  };

  // --------------------------------
  // Status updates
  // --------------------------------
  const updateFileStatus = (fileObj, status) => {
    setSelectedFiles((prev) =>
      prev.map((f) => (f === fileObj ? { ...f, status } : f))
    );
  };
  const updateFileProgress = (fileObj, progress) => {
    setSelectedFiles((prev) =>
      prev.map((f) => (f === fileObj ? { ...f, progress } : f))
    );
  };

  // --------------------------------
  // Handle Upload
  // => We assume we have driveId & folderId from the selected folder
  // --------------------------------
  const handleUpload = async () => {
    if (!driveId || !folderId) {
      alert('No folder selected for upload.');
      return;
    }
    const pendingFiles = selectedFiles.filter((f) => f.status === 'pending');
    for (const fileObj of pendingFiles) {
      try {
        updateFileStatus(fileObj, 'uploading');
        // The graphService.uploadFile logic would need to handle uploading to a 
        // specific drive/folder, for example:
        // PUT /drives/{driveId}/items/{folderId}:/{fileName}:/content
        await graphService.uploadFileToDriveFolder(
          driveId,
          folderId,
          fileObj.file,
          (progress) => {
            updateFileProgress(fileObj, progress);
          },
          fileObj.name // new name
        );
        updateFileStatus(fileObj, 'uploaded');
      } catch (error) {
        console.error('Error uploading file:', error);
        updateFileStatus(fileObj, 'error');
      }
    }
  };

  // --------------------------------
  // Render
  // --------------------------------
  return (
    <div className="file-upload-container" style={{ padding: '20px' }}>
      <h2>Image Upload Flow</h2>

      {/* STEP 1: TEAMS */}
      {!selectedTeam && (
        <>
          <h3>Select a Team</h3>
          <input
            type="text"
            placeholder="Search Teams"
            value={teamSearch}
            onChange={(e) => setTeamSearch(e.target.value)}
            ref={teamSearchRef}
            style={{ marginBottom: '10px', width: '100%', padding: '8px' }}
          />
          <DataTable
            value={filteredTeams}
            selectionMode="single"
            onSelectionChange={onTeamSelect}
            dataKey="id"
            style={{ marginBottom: '20px' }}
          >
            <Column field="displayName" header="Team Name" />
          </DataTable>
        </>
      )}

      {/* STEP 2: CHANNELS */}
      {selectedTeam && !selectedChannel && (
        <>
          <h3>Select a Channel in {selectedTeam.displayName}</h3>
          <input
            type="text"
            placeholder="Search Channels"
            value={channelSearch}
            onChange={(e) => setChannelSearch(e.target.value)}
            style={{ marginBottom: '10px', width: '100%', padding: '8px' }}
          />
          <DataTable
            value={filteredChannels}
            selectionMode="single"
            onSelectionChange={onChannelSelect}
            dataKey="id"
            style={{ marginBottom: '20px' }}
          >
            <Column field="displayName" header="Channel Name" />
          </DataTable>
        </>
      )}

      {/* STEP 3: FOLDERS */}
      {selectedTeam && selectedChannel && !uploadStepVisible && (
        <>
          <h3>
            Folder Structure in {selectedChannel.displayName} (Team: {selectedTeam.displayName})
          </h3>
          <p>Select or navigate into a folder. (Click subfolder to drill down.)</p>
          <input
            type="text"
            placeholder="Search Folders"
            value={folderSearch}
            onChange={(e) => setFolderSearch(e.target.value)}
            style={{ marginBottom: '10px', width: '100%', padding: '8px' }}
          />
          <DataTable
            value={filteredFolders}
            selectionMode="single"
            onSelectionChange={onFolderSelect}
            dataKey="id"
            style={{ marginBottom: '20px' }}
          >
            <Column field="name" header="Folder/File Name" />
          </DataTable>

          <button onClick={goToUploadStep} style={{ padding: '10px 20px' }}>
            Proceed to Upload
          </button>
        </>
      )}

      {/* STEP 4: UPLOAD UI */}
      {uploadStepVisible && (
        <>
          <h3>Upload Images to Folder: {selectedFolder?.name}</h3>
          <p>Drive: {driveId}</p>
          <p>Folder ID: {folderId}</p>

          <div className="file-selection" style={{ marginBottom: '20px' }}>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              multiple
              onChange={handleFileSelection}
              style={{ display: 'none' }}
              id="captureInput"
            />
            <label htmlFor="captureInput">
              <span className="capture-button" style={{ padding: '10px 15px', backgroundColor: '#0078d4', color: '#fff', borderRadius: '5px', cursor: 'pointer' }}>
                Select/Take Photo
              </span>
            </label>
          </div>

          <div className="file-list" style={{ marginBottom: '20px' }}>
            {selectedFiles.length === 0 && (
              <p>No photos selected yet.</p>
            )}
            {selectedFiles.map((fileObj, index) => (
              <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                <p><strong>Original Name:</strong> {fileObj.file.name}</p>
                <label>
                  <strong>Rename:</strong>
                  <input
                    type="text"
                    value={fileObj.name}
                    onChange={(e) => handleNameChange(index, e.target.value)}
                    style={{ marginLeft: '10px', padding: '4px' }}
                  />
                </label>
                <p><strong>Status:</strong> {fileObj.status}</p>
                {fileObj.status === 'uploading' && (
                  <div style={{ marginTop: '6px' }}>
                    <progress value={fileObj.progress} max="100">
                      {fileObj.progress}%
                    </progress>
                    <span> {fileObj.progress.toFixed(2)}%</span>
                  </div>
                )}
                {fileObj.status === 'uploaded' && (
                  <p style={{ color: 'green' }}>Uploaded successfully!</p>
                )}
                {fileObj.status === 'error' && (
                  <p style={{ color: 'red' }}>Error uploading</p>
                )}
              </div>
            ))}
          </div>

          <button
            onClick={handleUpload}
            style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', borderRadius: '5px', cursor: 'pointer' }}
            disabled={selectedFiles.length === 0}
          >
            Upload Files
          </button>
        </>
      )}

      {/* Loading Indicator */}
      {loading && <p>Loading data, please wait...</p>}
    </div>
  );
};

export default FileUpload;
