import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Import the same CSS or replicate .searchContainer, .searchLogo, and .searchInput
import './TeamsChannelsPicker.css';

import logo from './logo.jpg';
import loadingGif from '../components/gifs/loading.gif';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import GraphService from '../utils/GraphService';
import { getAccessToken } from '../utils/auth';

const graphService = new GraphService();

const TeamsChannelsPicker = () => {
  const navigate = useNavigate();

  // ---------------------------------------------------------
  // 1) TEAMS STATE
  // ---------------------------------------------------------
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [teamSearch, setTeamSearch] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);

  // ---------------------------------------------------------
  // 2) CHANNELS STATE
  // ---------------------------------------------------------
  const [channels, setChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [channelSearch, setChannelSearch] = useState('');
  const [selectedChannel, setSelectedChannel] = useState(null);

  // ---------------------------------------------------------
  // 3) FOLDERS/FILES STATE
  // ---------------------------------------------------------
  const [folderItems, setFolderItems] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [folderSearch, setFolderSearch] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [driveId, setDriveId] = useState(null);
  const [folderId, setFolderId] = useState(null);

  // ---------------------------------------------------------
  // 4) FILE UPLOAD STATE
  // ---------------------------------------------------------
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStepVisible, setUploadStepVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // NEW: interval ID for periodic file-check
  const [checkIntervalId, setCheckIntervalId] = useState(null);

  // NEW: For the "uploading" animation dots
  const [uploadingDots, setUploadingDots] = useState(0);

  // ---------------------------------------------------------
  // Helper function to check for conflicts
  // ---------------------------------------------------------
  const setConflictFlags = (fileObjs, folderItemsList) => {
    return fileObjs.map((fileObj) => {
      const newFullName = fileObj.extension
        ? `${fileObj.baseName}.${fileObj.extension}`
        : fileObj.baseName;

      const conflict = folderItemsList.some(
        (item) => item.name.toLowerCase() === newFullName.toLowerCase()
      );
      return { ...fileObj, conflict };
    });
  };

  // ---------------------------------------------------------
  // On mount => fetch teams
  // ---------------------------------------------------------
  useEffect(() => {
    fetchTeams();

    // Cleanup any leftover interval if user navigates away
    return () => {
      if (checkIntervalId) {
        clearInterval(checkIntervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------------------------------------------------------
  // Animate the "uploading..." text
  // ---------------------------------------------------------
  useEffect(() => {
    const interval = setInterval(() => {
      // Cycles 0 -> 1 -> 2 -> 0 -> 1 -> ...
      setUploadingDots((prev) => (prev + 1) % 3);
    }, 600);
    return () => clearInterval(interval);
  }, []);

  const getUploadingText = () => {
    // 0 => "uploading."
    // 1 => "uploading.."
    // 2 => "uploading..."
    return `uploading${'.'.repeat(uploadingDots + 1)}`;
  };

  // ---------------------------------------------------------
  // Fetch Teams
  // ---------------------------------------------------------
  const fetchTeams = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(['Team.ReadBasic.All']);
      const response = await fetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch Teams: ${response.statusText}`);
      }
      const data = await response.json();
      setTeams(data.value || []);
    } catch (error) {
      console.error('Error fetching Teams:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  // Filter teams
  // ---------------------------------------------------------
  useEffect(() => {
    applyTeamsFilter();
  }, [teams, teamSearch]);

  const applyTeamsFilter = () => {
    const filtered = teams.filter((t) =>
      t.displayName.toLowerCase().includes(teamSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
    );
    setFilteredTeams(filtered);
  };

  // ---------------------------------------------------------
  // On team row select => fetch channels
  // ---------------------------------------------------------
  const onTeamSelect = (e) => {
    const team = e.value;
    setSelectedTeam(team);
    fetchChannelsForTeam(team.id);
  };

  const fetchChannelsForTeam = async (teamId) => {
    if (!teamId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Channel.ReadBasic.All']);
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch channels: ${response.statusText}`);
      }
      const data = await response.json();
      setChannels(data.value || []);
    } catch (error) {
      console.error('Error fetching channels:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  // Filter & sort Channels
  // ---------------------------------------------------------
  useEffect(() => {
    applyChannelsFilter();
  }, [channels, channelSearch]);

  const applyChannelsFilter = () => {
    const filtered = channels.filter((c) =>
      c.displayName.toLowerCase().includes(channelSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
    );
    setFilteredChannels(filtered);
  };

  // ---------------------------------------------------------
  // On channel select => fetch root folder
  // ---------------------------------------------------------
  const onChannelSelect = (e) => {
    const channel = e.value;
    setSelectedChannel(channel);
    fetchChannelRootFolder(selectedTeam.id, channel.id);
  };

  const fetchChannelRootFolder = async (teamId, channelId) => {
    if (!teamId || !channelId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Files.ReadWrite.All']);
      const resp = await fetch(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/filesFolder`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (!resp.ok) {
        throw new Error(`Failed to fetch channel root folder: ${resp.statusText}`);
      }
      const folderData = await resp.json();
      const { id, parentReference } = folderData;
      const drive = parentReference?.driveId || null;

      setDriveId(drive);
      setFolderId(id);
      setSelectedFolder(folderData);

      // Fetch children
      fetchFolderChildren(drive, id);
    } catch (error) {
      console.error('Error fetching channel root folder:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  // Fetch children of folder
  // ---------------------------------------------------------
  const fetchFolderChildren = async (theDriveId, theFolderId) => {
    if (!theDriveId || !theFolderId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Files.ReadWrite.All']);
      const url = `https://graph.microsoft.com/v1.0/drives/${theDriveId}/items/${theFolderId}/children`;
      const resp = await fetch(url, { headers: { Authorization: `Bearer ${token}` } });
      if (!resp.ok) {
        throw new Error(`Failed to fetch folder items: ${resp.statusText}`);
      }
      const data = await resp.json();
      setFolderItems(data.value || []);
    } catch (error) {
      console.error('Error fetching folder children:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  // Filter Folders (and files)
  // ---------------------------------------------------------
  useEffect(() => {
    applyFolderFilter();
  }, [folderItems, folderSearch]);

  const applyFolderFilter = () => {
    const filtered = folderItems.filter((f) =>
      f.name.toLowerCase().includes(folderSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true })
    );
    setFilteredFolders(filtered);
  };

  // ---------------------------------------------------------
  // On folder select => drill down if it's a folder
  // ---------------------------------------------------------
  const onFolderSelect = (e) => {
    const item = e.value;
    if (item.folder) {
      fetchFolderChildren(item.parentReference?.driveId, item.id);
    }
    setSelectedFolder(item);
    setFolderId(item.id);
  };

  // ---------------------------------------------------------
  // Navigate to upload step
  // ---------------------------------------------------------
  const goToUploadStep = () => {
    setUploadStepVisible(true);
  };

  // ---------------------------------------------------------
  // File selection
  // ---------------------------------------------------------
  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);

    let filesWithMetadata = files.map((file) => ({
      file,
      baseName: getBaseName(file.name),
      extension: getExtension(file.name),
      conflict: false,
      progress: 0,
      status: 'pending'
    }));

    filesWithMetadata = setConflictFlags(filesWithMetadata, folderItems);
    setSelectedFiles((prev) => [...prev, ...filesWithMetadata]);

    // Reset so user can reselect the same file if needed
    event.target.value = null;
  };

  // Helpers to parse file name
  const getBaseName = (fullName) => {
    const lastDot = fullName.lastIndexOf('.');
    if (lastDot === -1) return fullName; // no extension
    return fullName.substring(0, lastDot);
  };

  const getExtension = (fullName) => {
    const lastDot = fullName.lastIndexOf('.');
    if (lastDot === -1) return '';
    return fullName.substring(lastDot + 1);
  };

  const sanitizeFileName = (name) =>
    name.replace(/[<>:"/\\|?*]/g, '').trim();

  // ---------------------------------------------------------
  // Handle user renaming the file (baseName)
  // ---------------------------------------------------------
  const handleBaseNameChange = (index, newBase) => {
    let sanitizedBase = sanitizeFileName(newBase) || `Photo_${Date.now()}`;
    const updatedFiles = [...selectedFiles];
    const fileObj = updatedFiles[index];
    fileObj.baseName = sanitizedBase;

    const newFullName = fileObj.extension
      ? `${fileObj.baseName}.${fileObj.extension}`
      : fileObj.baseName;

    const conflict = folderItems.some(
      (item) => item.name.toLowerCase() === newFullName.toLowerCase()
    );
    fileObj.conflict = conflict;

    setSelectedFiles(updatedFiles);
  };

  const getFullFileName = (fileObj) => {
    return fileObj.extension
      ? `${fileObj.baseName}.${fileObj.extension}`
      : fileObj.baseName;
  };

  // ---------------------------------------------------------
  // Update file status & progress
  // ---------------------------------------------------------
  const updateFileStatus = (fileObj, status) => {
    setSelectedFiles((prev) =>
      prev.map((f) => (f === fileObj ? { ...f, status } : f))
    );
  };

  const updateFileProgress = (fileObj, progress) => {
    setSelectedFiles((prev) =>
      prev.map((f) => (f === fileObj ? { ...f, progress } : f))
    );
  };

  // ---------------------------------------------------------
  // Periodically check if files have appeared in folder
  // ---------------------------------------------------------
  const startUploadCheck = () => {
    if (checkIntervalId) {
      clearInterval(checkIntervalId);
    }

    const intervalId = setInterval(async () => {
      try {
        // Fetch folder items
        const token = await getAccessToken(['Files.ReadWrite.All']);
        const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}/children`;
        const resp = await fetch(url, { headers: { Authorization: `Bearer ${token}` } });
        if (!resp.ok) {
          throw new Error(`Failed to fetch folder items: ${resp.statusText}`);
        }
        const data = await resp.json();

        setSelectedFiles((prevFiles) => {
          return prevFiles.map((fileObj) => {
            if (fileObj.status === 'uploading' || fileObj.status === 'pending') {
              const fullName = getFullFileName(fileObj).toLowerCase();
              const found = data.value.some(
                (item) => item.name.toLowerCase() === fullName
              );
              if (found) {
                return { ...fileObj, status: 'uploaded' };
              }
            }
            return fileObj;
          });
        });

        // If all files are done (uploaded or error), clear interval
        setSelectedFiles((prevFiles) => {
          const allDone = prevFiles.every(
            (f) => f.status === 'uploaded' || f.status === 'error'
          );
          if (allDone) {
            clearInterval(intervalId);
            setCheckIntervalId(null);
          }
          return prevFiles;
        });
      } catch (error) {
        console.error('Error checking folder items:', error);
        clearInterval(intervalId);
        setCheckIntervalId(null);
      }
    }, 5000);

    setCheckIntervalId(intervalId);
  };

  // ---------------------------------------------------------
  // Handle upload
  // ---------------------------------------------------------
  const handleUpload = async () => {
    const hasConflict = selectedFiles.some((f) => f.conflict);
    if (hasConflict) {
      alert('One or more files have name conflicts. Please rename them before uploading.');
      return;
    }

    if (!driveId || !folderId) {
      alert('No folder selected (or no root folder found).');
      return;
    }

    const pendingFiles = selectedFiles.filter((f) => f.status === 'pending');
    for (const fileObj of pendingFiles) {
      try {
        updateFileStatus(fileObj, 'uploading');
        await graphService.uploadFileToDriveFolder(
          driveId,
          folderId,
          fileObj.file,
          (progress) => updateFileProgress(fileObj, progress),
          getFullFileName(fileObj)
        );
        // We'll let the "checker" confirm it appeared in the folder
      } catch (error) {
        console.error('Error uploading file:', error);
        updateFileStatus(fileObj, 'error');
      }
    }
    startUploadCheck();
  };

  // ---------------------------------------------------------
  // Handle Back Button
  // ---------------------------------------------------------
  const handleBack = () => {
    if (uploadStepVisible) {
      setUploadStepVisible(false);
    } else if (selectedChannel) {
      setSelectedChannel(null);
      setSelectedFolder(null);
    } else if (selectedTeam) {
      setSelectedTeam(null);
      setTeams([]);
      setFilteredTeams([]);
      fetchTeams(); // Re-fetch if needed
    }
  };

  // ---------------------------------------------------------
  // Handle Logo Click => navigate to home/dashboard
  // ---------------------------------------------------------
  const handleLogoClick = () => {
    navigate('/');
    // or navigate('/dashboard') if that's your route
  };

  // Build an inline "breadcrumb" heading: Team / Channel / Folder
  const renderBreadcrumbHeading = () => {
    let folderName = '';
    if (selectedFolder?.name) {
      folderName = ` / ${selectedFolder.name}`;
    }
    return (
      <h3>
        {selectedTeam?.displayName} / {selectedChannel?.displayName}
        {folderName}
      </h3>
    );
  };

  // ---------------------------------------------------------
  // Render
  // ---------------------------------------------------------
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Header Section (no padding => logo pinned left) */}
      <div style={{ position: 'relative' }}>
        {/* If no team selected => show "logo + search" */}
        {!selectedTeam && (
          <div className="searchContainer">
            <img
              src={logo}
              alt="Logo"
              onClick={handleLogoClick}
              className="searchLogo"
            />
            <input
              type="text"
              placeholder="Search Teams"
              value={teamSearch}
              onChange={(e) => setTeamSearch(e.target.value)}
              className="searchInput"
            />
          </div>
        )}

        {/* Back Button if "beyond no team selected" */}
        {(selectedTeam || selectedChannel || uploadStepVisible) && (
          <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
            <button
              onClick={handleBack}
              style={{
                padding: '10px 15px',
                backgroundColor: '#cccccc',
                color: '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Back
            </button>
          </div>
        )}
      </div>

      {/* Main Content Section */}
      <div style={{ flex: 1, overflow: 'auto', padding: '20px' }}>
        {/* TEAMS LIST */}
        {!selectedTeam && (
          <DataTable
            value={filteredTeams}
            selectionMode="single"
            onSelectionChange={onTeamSelect}
            dataKey="id"
            scrollable
            scrollHeight="100%"
            style={{ marginBottom: '20px' }}
          >
            <Column field="displayName" header="Team Name" />
          </DataTable>
        )}

        {/* CHANNELS LIST */}
        {selectedTeam && !selectedChannel && !uploadStepVisible && (
          <>
            <h3 style={{ textAlign: 'center' }}>
              Select a Channel in {selectedTeam.displayName}
            </h3>
            <input
              type="text"
              placeholder="Search Channels"
              value={channelSearch}
              onChange={(e) => setChannelSearch(e.target.value)}
              style={{
                marginBottom: '10px',
                width: '100%',
                padding: '8px'
              }}
            />
            <DataTable
              value={filteredChannels}
              selectionMode="single"
              onSelectionChange={onChannelSelect}
              dataKey="id"
              scrollable
              scrollHeight="100%"
              style={{ marginBottom: '20px' }}
            >
              <Column field="displayName" header="Channel Name" />
            </DataTable>
          </>
        )}

        {/* FOLDERS/FILES TABLE */}
        {selectedTeam && selectedChannel && !uploadStepVisible && (
          <>
            <div style={{ textAlign: 'center' }}>{renderBreadcrumbHeading()}</div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px'
              }}
            >
              <input
                type="text"
                placeholder="Search Folders"
                value={folderSearch}
                onChange={(e) => setFolderSearch(e.target.value)}
                style={{
                  flex: '1 1 auto',
                  minWidth: '200px',
                  padding: '8px'
                }}
              />

              <button
                onClick={goToUploadStep}
                style={{
                  padding: '12px 24px',
                  fontSize: '16px',
                  backgroundColor: '#0078d4',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none'
                }}
              >
                Proceed to Upload
              </button>
            </div>

            <DataTable
              value={filteredFolders}
              selectionMode="single"
              onSelectionChange={onFolderSelect}
              dataKey="id"
              scrollable
              scrollHeight="100%"
              style={{ marginBottom: '20px' }}
            >
              <Column field="name" header="Folder/File Name" />
            </DataTable>
          </>
        )}

        {/* UPLOAD STEP */}
        {uploadStepVisible && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              margin: '0 auto',
              maxWidth: '600px'
            }}
          >
            {renderBreadcrumbHeading()}

            <div style={{ marginBottom: '20px' }}>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                multiple
                onChange={handleFileSelection}
                style={{ display: 'none' }}
                id="fileInput"
              />
              <label htmlFor="fileInput">
                <span
                  style={{
                    padding: '10px 15px',
                    backgroundColor: '#0078d4',
                    color: '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                >
                  Select/Take Photo
                </span>
              </label>

              <button
                onClick={handleUpload}
                style={{
                  marginLeft: '20px',
                  padding: '10px 15px',
                  backgroundColor: '#28a745',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none'
                }}
                disabled={
                  selectedFiles.length === 0 ||
                  selectedFiles.some((fileObj) => fileObj.conflict)
                }
              >
                Upload Files
              </button>
            </div>

            {/* Selected Files List */}
            <div
              style={{
                width: '100%',
                maxHeight: '300px',
                overflowY: 'auto',
                marginBottom: '10px',
                textAlign: 'left'
              }}
            >
              {selectedFiles.length === 0 && <p>No photos selected yet.</p>}
              {selectedFiles.map((fileObj, index) => {
                const fullName = getFullFileName(fileObj);
                const previewUrl = URL.createObjectURL(fileObj.file);

                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      border: '1px solid #ccc',
                      padding: '10px',
                      marginBottom: '10px',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start'
                    }}
                  >
                    {/* LEFT SIDE */}
                    <div style={{ marginRight: '10px', flex: 1 }}>
                      <p>
                        <strong>Original Name:</strong> {fileObj.file.name}
                      </p>
                      <label>
                        <strong>Rename:</strong>
                        <input
                          type="text"
                          value={fileObj.baseName}
                          onChange={(e) => handleBaseNameChange(index, e.target.value)}
                          style={{
                            marginLeft: '10px',
                            padding: '4px',
                            borderColor: fileObj.conflict ? 'red' : '#ccc'
                          }}
                        />
                      </label>
                      {fileObj.extension && (
                        <span style={{ marginLeft: '8px' }}>
                          .{fileObj.extension}
                        </span>
                      )}
                      {fileObj.conflict && (
                        <p style={{ color: 'red', margin: '5px 0 0 0' }}>
                          A file named "{fullName}" already exists in this folder!
                        </p>
                      )}

                      <p>
                        <strong>Status:</strong>{' '}
                        {fileObj.status === 'uploading'
                          ? getUploadingText()
                          : fileObj.status}
                      </p>

                      {fileObj.status === 'error' && (
                        <p style={{ color: 'red' }}>Error uploading</p>
                      )}
                      {fileObj.status === 'uploaded' && (
                        <p style={{ color: 'green' }}>Uploaded successfully!</p>
                      )}
                    </div>

                    {/* RIGHT SIDE: Thumbnail */}
                    <div
                      style={{
                        width: '120px',
                        height: '120px',
                        border: '1px solid #ccc',
                        display: 'flex'
                      }}
                    >
                      <img
                        src={previewUrl}
                        alt="Preview"
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%'
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {/* Pin the bottom Upload Files button */}
      {uploadStepVisible && selectedFiles.length > 0 && (
        <button
          onClick={handleUpload}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            borderRadius: '5px',
            cursor: 'pointer',
            border: 'none'
          }}
          disabled={selectedFiles.some((fileObj) => fileObj.conflict)}
        >
          Upload Files
        </button>
      )}

      {/* LOADING INDICATOR */}
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '50px' }} />
        </div>
      )}
    </div>
  );
};

export default TeamsChannelsPicker;
