// src/App.js

import React, { createContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';

import AuthPage from './components/AuthPage';
import SpreadsheetSearch from './components/SpreadsheetSearch';
import FileUpload from './components/FileUpload';
import Dashboard from './components/Dashboard';
import TeamsChannelsPicker from './components/TeamsChannelsPicker'; // <-- New page import

import { msalInstance } from './utils/auth';

// Creating a context for global actions that can be triggered from child components
const GlobalActionsContext = createContext({
  performGlobalAction: () => {}
});

/**
 * AuthenticatedRoute Component
 * Protects routes by checking authentication status.
 * Redirects to the AuthPage if the user is not authenticated.
 */
const AuthenticatedRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/"); // Redirect to AuthPage if not authenticated
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : null;
};

/**
 * NotFound Component
 * Displays a 404 message for undefined routes.
 */
const NotFound = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h2>404 - Page Not Found</h2>
    <p>The page you're looking for doesn't exist.</p>
  </div>
);

/**
 * AppWrapper Component
 * Wraps the application with MsalProvider and GlobalActionsContext.
 * Sets up routing for different components with protected routes.
 */
const AppWrapper = () => {
  /**
   * performGlobalAction Function
   * Define any global actions here, such as refreshing data or logging out.
   */
  const performGlobalAction = () => {
    // Example global action
    console.log('Performing global action');
  };

  return (
    <MsalProvider instance={msalInstance}>
      <GlobalActionsContext.Provider value={{ performGlobalAction }}>
        <Router>
          <div className="appContainer">
            <Routes>
              {/* Public Route */}
              <Route path="/" element={<AuthPage />} />

              {/* Protected Routes */}
              <Route
                path="/dashboard"
                element={
                  <AuthenticatedRoute>
                    <Dashboard />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <AuthenticatedRoute>
                    <SpreadsheetSearch />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/file-upload"
                element={
                  <AuthenticatedRoute>
                    <FileUpload />
                  </AuthenticatedRoute>
                }
              />

              {/* NEW Protected Route for Teams/Channels Picker */}
              <Route
                path="/teams-channels"
                element={
                  <AuthenticatedRoute>
                    <TeamsChannelsPicker />
                  </AuthenticatedRoute>
                }
              />

              {/* Fallback Route for Undefined Paths */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </GlobalActionsContext.Provider>
    </MsalProvider>
  );
};

export default AppWrapper;
export { GlobalActionsContext };
