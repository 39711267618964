// src/utils/auth.js

import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Sign In with all scopes at once.
 * This will prompt the user (or admin) to consent to everything the first time.
 */
export const signIn = async () => {
  try {
    const response = await msalInstance.loginPopup(loginRequest);
    console.log("Login Success:", response.account.username);
    return response;
  } catch (error) {
    console.error("Login Failed:", error);
    throw error;
  }
};

/**
 * Acquire a token silently, or fallback to an interactive popup if needed.
 */
export const getAccessToken = async (scopes = loginRequest.scopes) => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) throw new Error("No accounts detected.");

    try {
      // Attempt silent token acquisition
      const silentResult = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes
      });
      return silentResult.accessToken;
    } catch (silentError) {
      if (silentError instanceof InteractionRequiredAuthError) {
        // If silent fails, fallback to interactive
        try {
          const interactiveResult = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
            scopes
          });
          return interactiveResult.accessToken;
        } catch (interactiveError) {
          throw interactiveError;
        }
      } else {
        throw silentError;
      }
    }
  } catch (error) {
    console.error("Error acquiring access token:", error);
    throw error;
  }
};
