// src/services/GraphService.js

import { getAccessToken } from '../utils/auth'; // Corrected import path

class GraphService {
    constructor() {
        this.graphEndpoint = "https://graph.microsoft.com/v1.0";
        this.siteId = "61db3e41-a0b6-496d-9a64-605373a14b33";
        this.driveId = "b!QT7bYbagbUmaZGBTc6FLM7jTBiLh5DtLj4Z5ZNhPApEzX2Ylz74CSLdEibdCo4e8";
        this.itemId = "01D67C4CENTB6SHIOBOVBIA3LSU6OQG34B";
        this.sessionId = null;
    }

    /**
     * Clears the content of a specific cell in a worksheet.
     * @param {string} worksheetName - The name of the worksheet.
     * @param {string} cellAddress - The address of the cell (e.g., "A1").
     */
    async clearCell(worksheetName, cellAddress) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for clearing the cell");
            return;
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/range(address='${cellAddress}')`;

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ values: [[""]] }) // Assuming an empty string clears the cell
            });

            if (!response.ok) {
                const errorDetail = await response.text();
                throw new Error(`Failed to clear the cell: ${response.statusText} - Details: ${errorDetail}`);
            }
            console.log('Cell cleared successfully');
        } catch (error) {
            console.error('Error clearing the cell:', error);
        }
    }

    /**
     * Creates a new session for workbook operations.
     * @param {boolean} persistChanges - Whether to persist changes after the session ends.
     */
    async createSession(persistChanges = true) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for creating session");
            return;
        }

        const url = `${this.graphEndpoint}/me/drive/items/${this.itemId}/workbook/createSession`;
        const body = { persistChanges };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error(`Failed to create session: ${response.statusText}`);
            }

            const data = await response.json();
            this.sessionId = data.id;
            console.log('Session created successfully', this.sessionId);
        } catch (error) {
            console.error('Error creating session:', error);
        }
    }

    /**
     * Closes the current workbook session.
     */
    async closeSession() {
        if (!this.sessionId) {
            console.log("No active session to close");
            return;
        }

        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for closing session");
            return;
        }

        const url = `${this.graphEndpoint}/me/drive/items/${this.itemId}/workbook/closeSession`;

        try {
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sessionId: this.sessionId })
            });

            this.sessionId = null;
            console.log('Session closed successfully');
        } catch (error) {
            console.error('Error closing session:', error);
        }
    }

    /**
     * Retrieves data from a specific worksheet.
     * @param {string} worksheetName - The name of the worksheet.
     * @returns {Array} - The values from the used range of the worksheet.
     */
    async getWorksheetData(worksheetName = 'Accounts') {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found");
            return [];
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/usedRange(valuesOnly=true)`;

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch worksheet data: ${response.statusText}`);
            }
            const data = await response.json();
            return data.values;
        } catch (error) {
            console.error('Error fetching worksheet data:', error);
            return [];
        }
    }

    /**
     * Updates a range of cells in a worksheet.
     * @param {string} worksheetName - The name of the worksheet.
     * @param {string} rangeAddress - The address of the range (e.g., "A1:B2").
     * @param {Array} values - The values to update the range with.
     * @param {boolean} forceUpdate - Whether to force the update by appending a space if initial update fails.
     */
    async updateRange(worksheetName, rangeAddress, values, forceUpdate = false) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for updating range");
            return;
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/range(address='${rangeAddress}')`;

        try {
            let response;
            if (forceUpdate) {
                response = await this.performPatchRequest(url, values, accessToken);
            } else {
                response = await this.performPatchRequest(url, values, accessToken);
                if (!response.ok) {
                    console.log('Retry with appended space character...');
                    const modifiedValues = values.map(row => row.map(value => `${value} `)); // Append space to each value
                    response = await this.performPatchRequest(url, modifiedValues, accessToken);
                    if (!response.ok) throw new Error('Retry failed');
                }
            }
            console.log('Range updated successfully');
        } catch (error) {
            console.error('Error updating the range:', error);
        }
    }

    /**
     * Updates a single cell in a worksheet.
     * @param {string} worksheetName - The name of the worksheet.
     * @param {string} cellAddress - The address of the cell (e.g., "A1").
     * @param {string} newValue - The new value for the cell.
     * @param {boolean} forceUpdate - Whether to force the update by appending a space if initial update fails.
     * @returns {Object} - The response object indicating success or failure.
     */
    async updateCell(worksheetName, cellAddress, newValue, forceUpdate = false) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for updating the cell");
            return { ok: false, error: "No Access Token found" };
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/range(address='${cellAddress}')`;

        // Use the updated performPatchRequest method that handles response interpretation
        const response = forceUpdate
            ? await this.performPatchRequest(url, [[newValue]], accessToken)
            : await this.performPatchRequest(url, [[newValue]], accessToken);

        // Retry logic remains unchanged but now correctly interprets the structured response
        if (!response.ok && !forceUpdate) {
            console.log('Initial attempt failed. Trying with appended space character...');
            return await this.performPatchRequest(url, [[`${newValue} `]], accessToken);
        }
        return response;
    }

    /**
     * Performs a PATCH request to update cell values.
     * @param {string} url - The API endpoint URL.
     * @param {Array} values - The values to update.
     * @param {string} accessToken - The access token for authorization.
     * @returns {Object} - An object indicating whether the request was successful.
     */
    async performPatchRequest(url, values, accessToken) {
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ values: values })
            });
            if (!response.ok) {
                // If the server response is not OK, construct an error message
                const errorDetail = await response.text();
                return { ok: false, error: `Failed to update: ${response.statusText} - Details: ${errorDetail}` };
            }
            // If the fetch request was successful, return ok: true
            return { ok: true };
        } catch (error) {
            // Catch any errors related to the fetch operation itself
            console.error('Fetch error:', error);
            return { ok: false, error: error.toString() };
        }
    }

    /**
     * Updates multiple cells in a worksheet using a batch request.
     * @param {string} sheetName - The name of the worksheet.
     * @param {Array} changes - An array of change objects containing cellAddress and newValue.
     * @returns {Object} - The response object indicating success or failure.
     */
    async updateCells(sheetName, changes) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for updating cells");
            return { ok: false, error: "No Access Token found" };
        }

        const batchRequest = changes.map(change => {
            return {
                id: change.cellAddress,
                method: 'PATCH',
                url: `/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${sheetName}/range(address='${change.cellAddress}')`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: {
                    values: [[change.newValue]]
                }
            };
        });

        const url = `${this.graphEndpoint}/$batch`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ requests: batchRequest })
            });

            if (!response.ok) {
                const errorDetail = await response.text();
                throw new Error(`Failed to update cells: ${response.statusText} - Details: ${errorDetail}`);
            }
            return { ok: true };
        } catch (error) {
            console.error('Error updating cells:', error);
            return { ok: false, error: error.toString() };
        }
    }

    // --------------------------
    // NEW FUNCTIONALITY: UPLOAD FILES
    // --------------------------

    /**
     * Uploads a file to a specific folder in SharePoint/OneDrive.
     * Uses XMLHttpRequest to track progress and allows for renaming the file.
     * 
     * @param {string} driveId - The ID of the drive where the folder is located.
     * @param {string} folderId - The ID of the folder where the file should be uploaded.
     * @param {File} file - The file to upload.
     * @param {function} onProgress - Callback function to report upload progress (percentage).
     * @param {string} renamedFileName - The new name for the file after renaming.
     * @returns {Promise<Object>} - Resolves with the uploaded file's data or rejects with an error.
     */
    async uploadFileToDriveFolder(driveId, folderId, file, onProgress, renamedFileName) {
        return new Promise(async (resolve, reject) => {
            try {
                const accessToken = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
                if (!accessToken) {
                    reject(new Error("No Access Token available for uploading files."));
                    return;
                }

                // Sanitize and set the new file name
                const sanitizedFileName = renamedFileName.replace(/[<>:"/\\|?*]/g, '').trim() || `Photo_${Date.now()}`;
                const uploadUrl = `${this.graphEndpoint}/drives/${driveId}/items/${folderId}:/${encodeURIComponent(sanitizedFileName)}:/content`;

                const xhr = new XMLHttpRequest();
                xhr.open("PUT", uploadUrl, true);
                xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
                xhr.setRequestHeader("Content-Type", file.type);

                // Track upload progress
                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable && onProgress) {
                        const percentComplete = (event.loaded / event.total) * 100;
                        onProgress(percentComplete);
                    }
                };

                // Handle response
                xhr.onload = () => {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        try {
                            const response = JSON.parse(xhr.responseText);
                            resolve(response);
                        } catch (err) {
                            reject(err);
                        }
                    } else {
                        reject(new Error(`Failed to upload file: ${xhr.status} ${xhr.statusText} - ${xhr.responseText}`));
                    }
                };

                // Handle network errors
                xhr.onerror = () => {
                    reject(new Error("Network error during file upload."));
                };

                // Send the file
                xhr.send(file);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default GraphService;
