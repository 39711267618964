// src/components/Dashboard.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import logo from './logo.jpg';

function Dashboard() {
  const navigate = useNavigate();

  return (
    <div className="dashboard-container">
      <img src={logo} alt="Company Logo" className="dashboard-logo" />

      <div className="dashboard-buttons">
        <button
          className="dashboard-button"
          onClick={() => navigate('/search')}
        >
          Search Accounts
        </button>
        {/* New button to open the Teams & Channels picker */}
        <button
          className="dashboard-button"
          onClick={() => navigate('/teams-channels')}
        >
          Image Upload to Teams
        </button>
      </div>
    </div>
  );
}

export default Dashboard;
